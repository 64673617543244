<template>
    <div class="home-index">
        <my-nav v-if="isWeixin" title="肿瘤免费用药患者招募" :hide="false"/>
        <!-- <my-nav title="临床招募" :hide="false"/> -->
        <div class='home-top'>
            <div class="home-title">临床试验匹配</div>
            <div class="home-sub">根据患者病历资料，快速推荐适合患者病情的试验项目</div>
            <div class="home-matchbtn"  @click="isShowMatch = true">开始匹配</div>
            <div class="home-btn" @click="isShowDisclaimers = true">免责声明</div>
        </div>
        <div class='home-mian'>
            <!-- 搜索框 -->
            <div class='search-div' @click="toSearchPage()">
               <van-icon class="icon-search " name="search" size="20"  />
                输入药物类型或药物名称搜索
            </div>
            <!-- 轮播图 -->
            <van-swipe class="gallery" :autoplay="3000" indicator-color="white">
                <van-swipe-item v-for="(item, i) in galleryList" :key="i" @click="galleryClick(item.refId)">
                    <img :src="baseUrl + item.filePath" alt="">
                </van-swipe-item>
            </van-swipe>
        </div>
        <!-- 病种、地区、招募状态 查询项目-->
        <div id='home-project' class='home-project'>
            <div :class="{'select-station':true, 'is-fixed':isfixed }">
                <div class='select-indicator'>
                    <div :class="{'select-indicator-item': true, 'select-tab-active': isCancerActive}" @click.stop='cancerClick' data-name="cancer">
                    {{illName}}
                        <img v-if="!isCancerActive" class='icon_triangle' src='../../assets/icon/icon_triangle.png' />
                        <img v-else class='icon_triangle' src='../../assets/icon/icon_triangle_active.png' />
                    </div>
                    <div :class="{'select-tab-active': isRegionActive}" class="select-indicator-item region " @click.stop='regionClick' data-name="region">
                    {{cityName}}
                        <img v-if="!isRegionActive" class='icon_triangle' src='../../assets/icon/icon_triangle.png' />
                        <img v-else class='icon_triangle' src='../../assets/icon/icon_triangle_active.png' />
                    </div>
                    <div :class="{'select-tab-active': isStatusActive , 'select-indicator-item': true}" @click.stop='statusClick' data-name="status">
                    {{statusVal}}
                        <img v-if="!isStatusActive" class='icon_triangle' src='../../assets/icon/icon_triangle.png'/>
                        <img v-else class='icon_triangle' src='../../assets/icon/icon_triangle_active.png'/>
                    </div>
                </div>
                <!-- select-item -->
                <div class='select-contianer'>
                    <!-- 遮罩层 -->
                    <!-- <div v-if="{{isCancerActive || isRegionActive || isStatusActive}}" class='select-contianer-mask'></div> -->
                    <!-- 病种 -->
                    <div :class="{'select-station-item': true,'cancer-contianer':true, 'select-station-disply':isCancerActive}" @click.stop.prevent="cancerClick">
                        <div style="background: #fff;">
                            <div class="cancer-scroll" style="width: 100%; height:220px;">
                                <template v-for="(item, i) in cancerList">
                                    <div 
                                    :class="{'select-item-active': item.ischeck }"
                                    :key="i" 
                                    @click.stop='cancerHandleSelect(i,item)'>
                                        {{item.illName}}
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <!-- 地区 -->
                    <div :class="{'select-station-item': true,'select-station-disply': isRegionActive ,'region-contianer':true} " @click.stop.prevent='regionClick'>
                        <!-- <div :class="{'regionAll-text': true, 'select-region-active': !provinceId}" @click.stop.prevent='allRegionClick' cityId>全部地区</div> -->
                        <div class='scroll-container'>
                            <div class="province ">
                                <div class="province-scroll" style="width: 100%;height:220px;">
            
                                    <template v-for="(item, i) in regionList">
                                    <div :key="i" 
                                    :class="{'select-region-active': item.ischeck }"
                                    @click.stop='regionHandleSelect(i,item.provinceId)'>
                                        {{item.province}}
                                    </div>
                                    </template>
                                </div>
                            </div>
                            <div class="city">
                                <div class="city-scroll"   style="width: 100%;height:220px;">
                                    <template v-for="(item, i) in cityList">
                                    <div  :key="i"
                                    :class="{'select-item-active': item.ischeck }"
                                    @click.stop.prevent='cityHandleSelect(i,item)' >
                                        {{item.city}}
                                    </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 状态 -->
                    <div :class="{'select-station-item': true, 'select-station-disply': isStatusActive , 'status-contianer':true}  " @click.stop.prevent='statusClick'>
                       
                        <template v-for="(item, i) in statusList">
                            <div  :key="i"
                            :class="{'select-item-active': item.ischeck }"
                            @click.stop.prevent='statusHandleSelect(i,item)' >
                                {{item.value}}
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <!-- 项目内容 -->
            <div class="project-result">
                    <van-list v-model="loading" :immediate-check="false" :finished="finished" :finished-text="finishtext" @load="onLoad">
                        <template v-for="(item, i) in projectList">
                            <projectItem :projectInfo="item" :key="i" />
                        </template>
                    </van-list>
            </div>
        </div>
        <!-- 弹框-->
        <!-- 免责声明 -->
        <van-popup v-model="isShowDisclaimers" class="disclaimers-popup">
            <div class='disclaimers-container'>
                <div class='disclaimers-title'>免责声明</div>
                <div class='disclaimers-content'>丁丁健康患者招募，致力于帮助患者找到合适的临床试验项目。项目信息均来源于国内权威网站或企业，但由于信息会不断更新，我们无法对所有信息的真实性进行全面的实质性的核查，望充分知悉。
                </div>
                <div class='disclaimers-btn' @click='isShowDisclaimers=false'>知道了</div>
            </div>
        </van-popup>
        <!-- 开始匹配 -->
        <van-popup v-model="isShowMatch" class="match-popup" position="bottom">
            <div class='match-dialog-content1' v-if="!isShowMatchKnow">
                <div style='border-bottom: 1rpx solid #ACABAD;'>
                <button class='upload-btn' @click.stop='isShowMatchKnow = true'>拍照上传病历</button>
                <div class='upload-tips'>系统自动识别病历内容，精准匹配</div>
                </div>
                <div class='match-dialog-otherway' @click='toQuestionPage'>没有病历在身边？试试我问你答的方式 》</div>
            </div>
            <div class='match-dialog-content2' v-else>
                <div>为帮您匹配到合适的试验项目和提高入组成功率，我们可能会查看您的内容并和您联系，请知悉。</div>
                <button @click.stop='agreeHandle'>知道了，同意</button>
            </div>
        </van-popup>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Wx from 'weixin-js-sdk';
import { Icon,  Swipe, SwipeItem ,List,Popup  } from 'vant'
import projectItem from '@/components/project/project-item';
import HomeApi from '@api/home/homeApi'
import PatientApi from '@api/patient/patientApi'
import commonApi from '@/api/commonApi.js';
import page from '@utils/page';
import myNav from '@/components/nav/nav';
import { isEmpty ,hzzmLoginCallBack} from '@/utils/common';

export default {
    components: {
        [Icon.name]: Icon,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        projectItem,
        List,
        [List.name]: List,
        [Popup.name]: Popup,
        myNav
    },
    data() {
        return {
            projectList: [],
            loading: false,
            finished: false,
            finishtext: '没有更多了',
            isLoading: false, //控制下拉刷新的加载动画
            galleryList: [],
            isShowDisclaimers: false,       // 是否显示免责声明
            isShowMatch: false,             // 显示匹配
            isShowMatchKnow: false,
            cancerList: [
            { id: '', illName: '全部病种', ischeck: true }],
            regionList: [],
            cityList: [],
            statusList: [
            { key: '', value: '全部状态', ischeck: true  },
            { key: 0, value: '未开始', ischeck: false },
            { key: 1, value: '招募中', ischeck: false },
            { key: 2, value: '招募完成', ischeck: false}
            ],
            isCancerActive: false,
            isRegionActive: false,
            isStatusActive:false,
            illId: '',
            illName: '病种',
            provinceId: '',
            cityId: '',
            cityName: '地区',
            status: '',
            statusVal: '招募状态',
            isfixed: false,
            offsetTop: 0,
            scrollTop: 0
        }
    },
    created() {
        this.getProjectList()
        this.getGallery()
        this.getIllList()
        this.getRegionList()
    },
    computed:{
        ...mapGetters([
            'baseUrl',
            'isWeixin',
            'token',
            'gzhBaseUrl'
        ])
    },
     mounted() {
        this.page = new page.pages(this.projectList);
        // this.offsetTop = document.getElementById('home-project').offsetTop
        // console.log('mounted',this.offsetTop, document.getElementById('home-project'))
        window.addEventListener('scroll', this.handleScroll, true); 

        // 将vue组件的要回调的函数暴露出去  将方法绑定到window下面，提供给app外部调用
        window['hzzmLoginCallBack'] = (token) => {
            console.log("1：登录成功后回调传入token：-"+ this.token)

            hzzmLoginCallBack( token)
        }

        this.wxJDKShare();
    },
    updated() {
        // console.log('handleScroll',this.scrollTop)
    },
    methods: {
        // touchmoveHandle(ev) {
        //     ev.preventDefault();
        // },
        removeScroll() {
            // this.isfixed = false;
            window.removeEventListener('scroll', this.handleScroll,true); //离开页面需要移除这个监听的事件
        },
        handleScroll() {
            const { offsetTop } = this
            this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            // console.log('handleScroll',this.scrollTop)
            this.isfixed = this.scrollTop >= offsetTop || false
        },
        clickScroll() {
            const { offsetTop, scrollTop, isCancerActive, isStatusActive, isRegionActive } = this
            // console.log('clickScroll',offsetTop, scrollTop)
            if(scrollTop - offsetTop < 0) {
                // console.log(offsetTop, scrollTop)
                window.scrollTo(0,offsetTop);
                // console.log(window.scrollTo)
            }
            // document.body.addEventListener('touchmove', touchmoveHandle, { passive: false })
            // if(!isCancerActive && !isStatusActive && !isRegionActive) {
            //     document.body.removeEventListener('touchmove', touchmoveHandle, { passive: false });
            // }
        },
          // 病种查询
        cancerClick() {
            Object.assign(this,{
                isCancerActive: !this.isCancerActive,
                isRegionActive: false,
                isStatusActive: false
            })
            this.clickScroll()
        },
        cancerHandleSelect(index, info) { 
            // console.log(e)
            const cancerList = this.cancerList.map(item => {item.ischeck = false ; return item});
            const { id, illName } = info
            cancerList[index].ischeck = true;
             Object.assign(this,{
                pageNo: 1,
                illId: id,
                cancerList,
                isCancerActive: !this.isCancerActive,
                illName: id ? illName : '病种'
            })
            this.page.recovery()
            this.getProjectList()
        },
            // 地区查询
        regionClick() {
            Object.assign(this,{
                isRegionActive: !this.isRegionActive,
                isCancerActive: false,
                isStatusActive: false
            })
            this.clickScroll()
        },
        allRegionClick() {
            const regionList = this.regionList.map(item => { item.ischeck = false; return item });
            const cityList = [];
            // const cityList = regionList[0].cityList.map(item => { item.ischeck = false; return item });
            // regionList[0].ischeck = true;
            // cityList[0].ischeck = true;
            Object.assign(this,{
                provinceId: '',
                cityId: '',
                regionList,
                cityList,
                cityName: '全部地区',
                isRegionActive: !this.isRegionActive,
            })
            this.page.recovery()
            this.getProjectList()
        },
        regionHandleSelect(index, provinceId) {
            // console.log('regionHandleSelect')
            // console.log(e)
            const regionList = this.regionList.map(item => { item.ischeck = false; return item });
            const cityList = regionList[index].cityList.map(item => { item.ischeck = false; return item });
            regionList[index].ischeck = true;
            console.log('cityList[0]',cityList[0])
            cityList[0].ischeck = true;
            Object.assign(this,{
                regionList,
                cityList,
                provinceId
            })
            if(index === 0) {
                this.cityId = ''
                this.isRegionActive = !this.isRegionActive
                this.page.recovery()
                this.getProjectList()
            } 
        },
        cityHandleSelect(index, info) {
            // console.log('cityHandleSelect')
            const { cityId, city } = info
            const cityList = this.cityList.map(item => { item.ischeck = false; return item });
            cityList[index].ischeck = true;
            Object.assign(this,{
                pageNo: 1,
                cityId,
                cityList,
                cityName: cityId ? city : '地区',
                isRegionActive: !this.isRegionActive,
             
            })
            this.page.recovery()
            this.getProjectList()
        },
        // 状态查询
        statusClick() {
            Object.assign(this,{
                isStatusActive: !this.isStatusActive,
                isRegionActive: false,
                isCancerActive: false
            })
            this.clickScroll()
        },
        statusHandleSelect(index, info) {
            // console.log(e)
            const { key, value } = info
            const statusList = this.statusList.map(item => { item.ischeck = false; return item });
            statusList[index].ischeck = true;
            Object.assign(this,{
                pageNo:1,
                statusList,
                isStatusActive:  !this.isStatusActive,
                statusVal: key !== '' ? value : '招募状态',
                status: key
            })
            this.page.recovery()
            this.getProjectList()
        },
        // 开始匹配
        agreeHandle() {
            Object.assign(this, {
                isShowMatch: false,
                isShowMatchKnow: false
            })
            if(this.token) {
                HomeApi.agreeUpload().then(res => {
                    this.$router.push({
                        path: '/uploadMedicalRecord',
                        query: {recordId: res.data, type: 2}
                    })
                    this.removeScroll()
                })
            } else if( isEmpty(this.token )  && !this.isWeixin ){
                console.log(" 2：app跳转登录页面 token为空-"+ this.gzhBaseUrl)
                window.JSBridgeApp('hzzmLogin', this.gzhBaseUrl)
                
            }else{
                this.$router.push({
                    path: '/login',
                    query: {}
                })
            }
            
        },
        // 跳转问题页面
        toQuestionPage() {
            Object.assign(this, {
                isShowMatch: false,
            })
            console.log(this.token)
            // 判断是否登录
            if(this.token && this.token != 'undefine' && this.token != 'null') {
                // 判断是否有患者
                PatientApi.hasInquiry().then(res => {
                    res.data ? this.$router.push({
                        path: '/selectPatient',
                        query: { type: 2 }
                    }) : this.$router.push({
                        path: '/crePatientAfterUpload',
                        query: {}
                    })
                    this.removeScroll()
                })
            }else if( isEmpty(this.token )  && !this.isWeixin ){
                console.log(" 2：app跳转登录页面 token为空-"+ this.gzhBaseUrl)
                window.JSBridgeApp('hzzmLogin', this.gzhBaseUrl)
                
            } else {
                this.$router.push({
                    path: '/login',
                    query: {}
                })
            }
            
        },
        // 轮播跳转
        galleryClick(id) {
            this.$router.push({
                path: '/clinicalTrialDeail',
                query: { id }
            })
            this.removeScroll()
        },
        //跳转搜索
        toSearchPage() {
            this.$router.push({
                path: '/searchIndex'
            })
            this.removeScroll()
        },
        // 上拉加载
        onLoad() {
            this.pageNo = this.page.pageNo + ""
            this.page.isOver().then(() => {
                 this.finished = true;
                return;
            })
            this.getProjectList()
        },
        initPage() {
            const { cancerList } = this.data
            
        },
        // 获取轮播
        getGallery() {
            HomeApi.getGallery().then(res => {
                const { list } = res.data
                this.galleryList = list
            })
        },
        // 获取项目列表
        getProjectList() {
            const { illId, cityId, status, provinceId, projectList, pageNo } = this
            HomeApi.getProjectList({illId, cityId, status, provinceId, pageNo, pageSize: 20}).then(res =>{
                const { list } = res.data
                this.page.req(res).then(list => {
                    // console.log(list)
                    this.projectList = list
                    this.loading = false;
                })
                this.offsetTop = document.getElementById('home-project').offsetTop
                document.getElementById('home-project').style.minHeight = this.offsetTop +'px'
                //  console.log('getProjectList', this.offsetTop)
                // this.isShowProject =  this.projectList.length > 0 || false
            })
        },
        getIllList() {
            const { cancerList } = this
            HomeApi.getIllList().then(res =>{
                this.cancerList = cancerList.concat(res.data)
            })
        },
        getRegionList() {
            HomeApi.getRegionList().then(res =>{
                res.data.unshift({cityList:[{ischeck:true}], provinceId: '',province: '全部地区'})
                const regionList = res.data.map(item => { item.ischeck = false; return item }) 
                const cityList = res.data[0].cityList
                regionList[0].ischeck = true
                // cityList[0].ischeck = true
                Object.assign(this,{
                    regionList: res.data,
                    cityList: cityList
                })
            })
        },
        //微信环境下的右上角分享配置
        wxJDKShare(){
            if(this.isWeixin){
                //在微信网页里的分享
                let localLink=location.href.split('#')[0];
                console.log("localLink:---"+encodeURIComponent(localLink));
                commonApi.getWxConfig(encodeURIComponent(localLink)).then(rs => {
                    let title='免费用药项目查询';
                    let desc='快速匹配适合您的临床试验项目，在线提交报名信息。';
                    let imgurl='http://api.healthme.cn/share/logo.png';
                    Wx.config({
                        debug: false,////生产环境需要关闭debug模式
                        appId: rs.data.appId,//appId通过微信服务号后台查看
                        timestamp: rs.data.timestamp,//生成签名的时间戳
                        nonceStr: rs.data.nonceStr,//生成签名的随机字符串
                        signature: rs.data.signature,//签名
                        jsApiList: ['updateAppMessageShareData','updateTimelineShareData'] // 必填，需要使用的JS接口列表
                    });
                    Wx.ready(function() {

                        Wx.updateAppMessageShareData({
                            title:  title, // 分享标题
                            desc: desc, // 分享描述
                            link: localLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl: imgurl, // 分享图标(不能赋相对路径，一定要是绝对路径)
                            success: () => {
                            }
                        });
                        Wx.updateTimelineShareData({
                            title:  title+"，"+desc, // 分享标题
                            link: localLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl: imgurl, // 分享图标(不能赋相对路径，一定要是绝对路径)
                            success: () => {
                            }
                        });
                    });
                });
            }
        }
    },
    destroyed() {
        this.removeScroll()
    }
}
</script>

<style lang="less" scoped>
.home-index {
    .home-top {
        width: 100%;
        height: 7.68rem;
        background: url('../../assets/home_banner.png') no-repeat;
        background-size: 100% 100%;
        padding-top: .666667rem;
        div {
            text-align: center;
            color: #fff;
        }
        .home-title {
            font-size: 23px;

        }
        .home-sub {
            font-size: 14px;
            margin: .32rem 0 .8rem;
        }
        .home-matchbtn {
            font-size: 18px;
            width: 7.866667rem;
            height: 1.333333rem;
            line-height: 1.333333rem;
            background:linear-gradient(180deg,rgba(255,255,255,1) 32%,rgba(255,255,255,0.6) 100%);
            box-shadow:0px 6px 10px 0px rgba(18,55,107,0.5);
            border-radius: .08rem;
            color:#0050A2;
            margin: 0 auto;
        }
        .home-btn {
            font-size: 13px;
            color: #A3C3E4;
            margin-top: 1.2rem;
        }
    }
    .home-mian {
        width:100%;
        background:rgba(255,255,255,1);
        box-shadow:0px -0.16rem .226667rem 0px rgba(18,55,107,1);
        border-radius: .8rem .8rem 0px 0px;
        margin-top: -0.8rem;
        padding: 1.066667rem .4rem;
        position: relative;
        z-index: 555;
        .search-div {
            width: 9.2rem;
            height: 1.066667rem;
            background:rgba(255,255,255,1);
            box-shadow:0px .026667rem .2rem 0px rgba(153,153,153,0.5);
            border-radius: .533333rem;
            font-size: 14px;
            color: #ACABAD;
            /* line-height: 78rpx; */
            margin: 0 auto .8rem;
            padding: .266667rem .426667rem;
            .icon-search {
                vertical-align: middle;
                margin-right: 8rpx;
            }
        }
        .gallery {
            width: 100%;
            height: 4.36rem;
            border-radius: .133333rem;
            img{
                width: 100%;
                height: 4.36rem;
                border-radius: .133333rem;
            }
        }
    }
    .home-project {
        min-height: 552px;
        padding-bottom: 1.066667rem;
        background: #f7f7f7; 
        .select-station{
            width: 100%;
            height: 1.2rem;
            border-top:1px solid #D5D5D5;
            border-bottom:1px solid #D5D5D5;
            background: #fff;
            position: relative;
            .select-indicator{
                height: 100%;
                display: flex;
                align-items: center;
                .select-indicator-item{
                    width: 33.3%;
                    font-size: 16px;
                    text-align: center;
                }
                .icon_triangle {
                    width: .133333rem;
                    height: .106667rem;
                    vertical-align: middle;
                    margin-left: .453333rem;
                }
                .region {
                    border-left:  1px solid #D5D5D5;
                    border-right:  1px solid #D5D5D5;
                }
            }
            .select-contianer-mask {
                width:100%;
                height:100vh;
                background:rgba(0,0,0,0.3);
                position: absolute;
                top: 1.2rem;
                left: 0;
                z-index: 1;
            }
            .select-station-item {
                width: 100%;
                position: absolute;
                top: 1.15rem;
                left: 0;
                background: #fff;
                z-index: 222;
                height: 0;
                transition: all 0.2s;
                -moz-transition: all 0.2s;
                -webkit-transition: all 0.2s;
                -o-transition: all 0.2s;
                overflow: hidden;
            /* padding-bottom: 30rpx; */
            }    
            .select-station-disply {
                height: calc(100vh - 1.2rem);
                background:rgba(0,0,0,0.3);
            }
            .scroll-container {
                width: 100%;
                // height: 177px;
                background: #fff;
                display: flex;

            }
            .select-tab-active  {
                color: #0060C2 !important;
            }
            .select-item-active {
                color: #0060C2 !important;
                background: #F2F2F2 !important;
                position: relative;   
            }
            .select-region-active {
                color: #0060C2 !important;
                background: #F2F2F2 !important;
                position: relative;   
                &::before {
                    content: '';
                    width: 4px;
                    height: 1.133333rem;
                    background:rgba(0,96,194,1);
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
        .is-fixed {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 999;
        }
        .select-contianer {
            // .select-station-disply {
                .cancer-scroll {
                    height: 220px;
                    overflow: auto;
                    -webkit-overflow-scrolling: touch;
                    border-bottom: 1px solid #ACABAD;
                    div {
                        height: 41px;
                        line-height: 41px;
                        font-size:15px;
                        padding: 0 15px;
                        color: #828284;
                        background: #fff;
                    }
                }
            // }
            .status-contianer {
                div {
                    height: 41px;
                    line-height: 41px;
                    font-size:15px;
                    padding: 0 15px;
                    color: #828284;
                    background: #fff;
                }
            }
             .region-contianer {
                width: 100%;
                /* position: relative; */
                
                .regionAll-text {
                    height: 41px;
                    line-height: 41px;
                    font-size:15px;
                    padding: 0 15px;
                    color: #828284;
                    width: 100%;
                    background: #fff;
                }
                .province{
                    width: 150px;
                    background: #fff;
                     .province-scroll {
                         overflow: auto;
                         -webkit-overflow-scrolling: touch;
                         div{
                            height: 41px;
                            line-height: 41px;
                            font-size: 15px;
                            padding: 0 15px;
                            color: #828284;
                        }
                     } 
                }
                .city {
                    width: 225px;
                    background: #f2f2f2;
                    .city-scroll {
                        overflow: auto;
                        -webkit-overflow-scrolling: touch;
                         div{
                            height: 41px;
                            line-height: 41px;
                            font-size: 15px;
                            padding: 0 15px;
                            color: #828284;
                        }
                    }
                }
            }
        }
        .project-result {
            padding: 0 .4rem;
        }
    }
    .disclaimers-popup {
        width: 9.2rem;
        padding: 1.6rem .533333rem;
        border-radius: .106667rem;
        .disclaimers-container {
        
            
            // background: #fff;
            .disclaimers-title{
                text-align: center;
                font-size: 20px;
                font-weight:500;
                color: #313134;
            }
            .disclaimers-content {
                font-size: 16px;
                color: #828284;
                line-height: 1.6;
                margin: .8rem 0;
                text-align: justify;
            }
            .disclaimers-btn {
                font-size:17px;
                color: #0060C2;
                font-weight:500;
                text-align: center;
            }
        }
    }
    .match-popup {
        padding: 0 .4rem;
        .match-dialog-content1 {
            margin: 1.066667rem 0 1.333333rem;
            text-align: center;
            .upload-btn{
                width:7.866667rem;
                height: 1.333333rem;
                font-size:17px;
                font-weight:500;
                line-height: 1.333333rem;
                background:#0060C2;
                border: none;
                border-radius: .266667rem;
                color: #fff;
                margin: 0 auto;
            }
            .upload-tips {
                font-size: 13px;
                color: #828284;
                text-align: center;
                padding: .4rem 0 .666667rem;
            }
            .match-dialog-otherway {
                font-size: 16px;
                font-weight:500;
                color: #0060C2;
                text-align: center;
                padding-top: .666667rem;
            }
        }
        .match-dialog-content2 {
            padding: 1.466667rem  .4rem 1.386667rem;
            div {
                font-size: 13px;
                color: #828284;
                line-height: 1.5;
            }
            button {
                width: 7.866667rem;
                height: 1.333333rem;
                font-size: 17px;
                font-weight:500;
                line-height: 1.333333rem;
                color: #fff;
                background:rgba(0,96,194,1);
                border: none;
                border-radius: .266667rem;
                margin: 1.2rem auto 0;
            }
        }
    }
    
}
</style>
