import http from '@/utils/request'
import Qs from 'qs'
export default {
    getIllList: () => http.get('/mini/recruit/ill/list'), //获取病种列表
    // 开始匹配_同意上传病历
    agreeUpload: () => http.post('/wx/recruit/img/upload/agreeUpload'),
    // 开始匹配_同意上传病历
    getRegionList: () => http.get('/wx/recruit/project/hospital/list'),
    // 项目列表（分页--传20条
    getProjectList: (data) => http.get('/wx/recruit/project/indexPage', { params: data }),
    //获取轮播图
    getGallery: (data) => http.get('/wx/recruit/web/gallery/list', data),
}