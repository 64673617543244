<template>
	<div class="container">
		<div class="header">
			<div class="title">
				{{title}}
			</div>
			<div class="header_menu" v-if="hide" @click="jump" >
				<img :src="search_nav" alt="">
			</div>
			<div class="header_menu" @click="popPerson" v-if="isPerson" :style="!hide?'right:1.47rem':''">
				<img :src="person_nav" alt="">
			</div>
			<div class="header_menu" @click="popMenu" :style="!isPerson&&hide?'right:0.4rem':'right:0.4rem'">
				<img :src="menubutton" alt="">
			</div>
			<div v-if="isShow" class="popBody">
				<div class="item" v-for="(item, i) in navList" @click="link(i,item.url)" :key="i">
					<div class="item_icon">
						<img :src="item.icon" alt="">
					</div>
					<div class="item_text">{{item.title}}</div>
				</div>
			</div>
		</div>
	</div>
	
</template>
 
<script>
	import { mapGetters, mapActions } from 'vuex';
	// import logo_nav from "@assets/logo_nav.png"
	// import close_nav from "@assets/close_nav.png"
	import search_nav from "@assets/icon/search_nav.png"
	import person_nav from "@assets/icon/person_nav.png"
	import menubutton from "@assets/icon/menubutton.png"
    // const heathlmeUrl = 'http://gzhtest.healthme.cn'
    const heathlmeUrl = 'http://gzh.healthme.cn'
	export default {
		computed:{
		    ...mapGetters({ // 获取用户信息
		        isWeixin: 'isWeixin',
		    })
		},
		props: {
		  isPerson:{
			 type: Boolean,
			 default: true 
		  },
		  isAsd:{
			 type: Boolean,
			 default: true 
		  },
		  title: {
		    type: String,
		    default: ''
		  },
		  hide:{
			 type: Boolean,
			 default: false 
		  },
		  type:{
			 type: String,
			 default:'0' 
		  }
		},
		data() {
		    return {
				// logo_nav,
				// close_nav,
				search_nav,
				person_nav,
                isShow:false,
                menubutton,
                navList: [
                    {
                        icon: require('@/assets/icon/one.png'),
                        title: '找医生',
                        url: `${heathlmeUrl}/advisory/doctorList`
                    },
                    {
                        icon: require('@/assets/icon/two.png'),
                        title: '问医生',
                        url: `${heathlmeUrl}/`
                    },
                    {
                        icon: require('@/assets/icon/three.png'),
                        title: '找药品',
                        url: `${heathlmeUrl}/drug`
                    },
                    {
                        icon: require('@/assets/icon/four.png'),
                        title: '买保险',
                        url: `${heathlmeUrl}/insuranceList`
                    },
                    {
                        icon: require('@/assets/icon/five.png'),
                        title: '健康商城',
                        url: `${heathlmeUrl}/hypermarket`
                    },
                    {
                        icon: require('@/assets/icon/medicine.png'),
                        title: '临床招募',
                        url: '/'
                    },
                ]
			}
		},
		methods:{
			popPerson(){
				// this.$router.push({
				//     path: `${heathlmeUrl}/mine/myHome`,
				// })
				 window.location.href =  `${heathlmeUrl}/mine/myHome`;
			},
			link(i, url){
				if(i === 5){
					this.$router.push({
                        path:url,
                    })
                    this.isShow=!this.isShow
					return;
                }
                window.location.href = url;
			}, 
			jump(){
				if(this.type=='0'){
					this.$router.push({
					    path: '/drug/search',
					})
				}
				/*弹出 搜索框 */
				else if(this.type=='1'){
					this.$emit("showSearch")
				}else{
					this.$router.push({
					    path: '/advisory/doctorList',
						query:{
							isShow:true
						}
					})
				}
			},
			iconClose(){
				 this.$store.commit('SET_ISCLOSE', true);
			},
			popMenu(){
				this.isShow=!this.isShow
			},
		}
		
	}
</script>

<style lang="less" scoped>
	.container{
        width: auto;
        height: auto;
        .header {
            width: 100%;
            height: 1.33rem;
            background: rgba(255,255,255,1);
            position: relative;
            .title {
                height: 1.33re;
				line-height: 1.4rem;
				display:inline-block;
				position: absolute;
				color: rgba(42,42,46,1);
				font-size: 0.48rem ;
				left: .4rem;
				// background green
            }
            .popBody {
                padding: .55rem .81rem;
				width: 100%;
				height: 2.93rem;
				background: rgba(247,247,247,1) ;
				position: absolute;
				left :0rem;
				top: 1.33rem;
                z-index: 99999;
                .item{
                    height: 0.8rem;
					display: inline-block;
					// background red
					width: 33%;
					
					.item_icon {
                        display: inline-block;
						img {
                            width: .53rem;
							height: .53rem;
							vertical-align: text-top;
							margin-top: .14rem;
                        }
							
                    }
						
					.item_text {
                        vertical-align: text-top;
						// background #00979D
						display :inline-block;
						color: rgba(42,42,46,1);
						font-size: .4rem;
						height: .8rem;
						line-height: .8rem;
						margin-left: .3rem;
                    }
						
                }
					
            }
				
				
			.header_menu {
                display: inline-block;
				position: absolute;
				// background red
				height: 1.33rem;
				top: .05rem;
				&:nth-child(2) {
                    right: 2.53rem;
                }
					
				&:nth-child(3) {
                    right: 1.47rem;
                }
					
				&:nth-child(4) {
                    right: .4rem;
                }
					
				img {
                    vertical-align: top;
					width: .53rem;
					height: .53rem;
					margin-top: 0.35rem;
                }
            }
        }		 
    }
</style>
